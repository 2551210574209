import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment/locale/pl';
import { Delete, Edit } from '@mui/icons-material';
import styled from 'styled-components';

import { PublicationDate, Tag, Information } from '../styles/GlobalStyles'

import Loading from './Loading';

import { BASE_URL } from "../baseurl";

import useAuth from '../hooks/useAuth';
import { AuthContext } from '../context/authContext';

const Container = styled.div`
    padding: 10px 50px;
    margin-bottom: 40px;
    
    @media (max-width: 576px) {
        padding: 10px 20px;
    }
`

const Title = styled.h1`
    margin-top: 5px;
    font-size: 3rem;
    font-weight: 400;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`

const ManageButton = styled.button`
    border: 1px solid grey;
    border-radius: 3px;
    padding: 3px;
    margin: 0 3px;
    cursor: pointer;
`

const Author = styled.p`
    margin: 20px 0;
    font-size: 1.0rem;
`

const Tags = styled.div`
    margin: 8px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

const ArticleBody = styled.div`
    margin-top: 10px;
    font-size: 1.2rem;
    line-height: 1.6;
    white-space: pre-line;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`

export const Article = props => {

    const {currentUser} = useContext(AuthContext)
    const { isAdmin } = useAuth()
    const navigate = useNavigate();
    const [articleAuthorId, setArticleAuthorId] = useState("");
    const [isOwner, setIsOwner] = useState(false)
    const [isPublic, setIsPublic] = useState(false)

    useEffect(() => {
        setArticleAuthorId(props.article.author_id)
        setIsOwner(false);
        (articleAuthorId === currentUser?.user?.publicId) && setIsOwner(true);
        setIsPublic(false)
        props.article.public && setIsPublic(true)
    }, [props.article.author_id, props.article.public, articleAuthorId, currentUser?.user?.publicId])

    const authAxios = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `Bearer ${currentUser?.user?.token}`
        }
    })

    const deleteArticle = async (id) => {
        try {
            await authAxios.delete(`${BASE_URL}/api/v1/delete/${id}`)
            .then(function (response) {
                if (response.data.result === 'deleted') navigate('/')
              })
        } catch(err) {
            console.log(err)
        } 
    }

    const handleTagClick = async (tag) => {
        var tagWHash = tag.substring(1);

        try {
            await axios.get(`${BASE_URL}/api/v1/search/${tagWHash}`)
            .then(function (res) {
                navigate('/found', {state: res.data});
              })
        } catch (err) {
            if (err.response.status === '404') navigate('/found');
        }
    }

  return (

    props.article.title ?
        (isPublic || isAdmin || isOwner) ?
            <Container>
                <Title>{props.article.title}</Title>
                <PublicationDate><Moment format="DD MMMM YYYY HH:mm:ss">{props.article.publish_date}</Moment></PublicationDate>
                <Author>Autor: {props.article.author_name}</Author>
                <Tags>
                    <h4>Tagi:</h4>
                        {props.article.tags && (props.article.tags.map( tag => (
                            <Tag key={tag} onClick={() => handleTagClick(tag)}>{tag}</Tag>
                        )))}
                        </Tags>
                        {(isOwner || isAdmin) &&
                        <>
                            <ManageButton onClick={()=>deleteArticle(props.article.id)} title="Usuń artykuł">
                        <Delete/>
                    </ManageButton>
                    <Link to={'/add'}
                            state={{edit: true, id: props.article.id, title: props.article.title, body: props.article.body, tags: props.article.tags, public: props.article.public}}>
                        <ManageButton title="Edytuj artykuł"><Edit/></ManageButton>
                    </Link>
                </>}
                <ArticleBody dangerouslySetInnerHTML={{ __html: props.article.body}} />
            </Container>
        : <Information>Artykuł nie jest udostępniony publicznie !</Information>
    : <Loading/>
  )
}

export default Article