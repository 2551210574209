import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { getRandom } from './Home'

import axios from 'axios'
import styled from 'styled-components'

import { ColumnContainer, Left, Right } from '../styles/GlobalStyles'

import Navbar from '../components/Navbar'
import Articlesearch from '../components/ArticleSearch'
import Newestarticles from '../components/NewestArticles'
import Randomarticles from '../components/RandomArticles'
import Footer from '../components/Footer'
import ArticleShort from '../components/ArticleShort'

import { BASE_URL } from "../baseurl";

const SearchResultTitle = styled.p`
    text-align: center;
    margin: 20px 0;
    font-size: 2rem;
`

export const ArticlesFound = () => {

    const location = useLocation()

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      });

    const [articles, setArticles] = useState([])

    useEffect(() =>{
        const fetchAllArticles = async () =>{
            try {
                const res = await axios.get(`${BASE_URL}/api/v1/articles`)
                setArticles(res.data)
            } catch(err) {
                console.log(err)
            }
        }
        fetchAllArticles()
    }, [])

    var lastArticles = []
    var randomArticles = []
    if (articles.length > 0) {
        lastArticles = articles.slice(0, 5);
        randomArticles = getRandom(articles, articles.length<5 ? articles.length : 5)
    }

  return (
    <>
        <Navbar />
        <ColumnContainer>
            <Left>
                <SearchResultTitle>Wynik wyszukiwania:</SearchResultTitle>
                {location.state ?
                location.state.map(article=>(
                    <ArticleShort key={article.id} article={article} />
                )) : <h1>Brak wyników</h1>}
            </Left>
            <Right>
                <Articlesearch/>
                <Newestarticles lastArticles={lastArticles}/>
                <Randomarticles randomArticles={randomArticles}/>
            </Right>
        </ColumnContainer>
        <Footer/>
    </>
  )
}

export default ArticlesFound