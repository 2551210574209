import React, { useState } from 'react'
import styled from 'styled-components'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import UsersTable from '../components/UsersTable'
import ArticlesTable from '../components/ArticlesTable'

import useAuth from '../hooks/useAuth'

import { Information } from '../styles/GlobalStyles'

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
`

const Right = styled.div`
    flex: 5;
`

const Button = styled.button`
    padding: 7px;
    cursor: pointer;
    margin: 5px;
`

export const Panel = () => {

    const { isAdmin } = useAuth()
    const [content, setContent] = useState('users')

    const handleUsersClick = async (e) => {
        e.preventDefault()

        setContent('users')
    }

    const handleArticlesClick = async (e) => {
        e.preventDefault()

        setContent('articles')
    }

    return (
        <>
            <Navbar />
                { isAdmin ? 
                    <Container>
                        <Left>
                            <Button onClick={handleUsersClick}>Użytkownicy</Button>
                            <Button onClick={handleArticlesClick}>Artykuły</Button>
                        </Left>
                        <Right>
                            { content === 'users' ? <UsersTable/> : <ArticlesTable/> }
                        </Right>
                    </Container>
                    : <Information>Nie posiadasz uprawnień do tej podstrony !</Information> }
            <Footer/>
        </>
    )
}

export default Panel