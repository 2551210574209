import React from 'react'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import LoginForm from '../components/LoginForm'

export const Login = () => {

  return (
    <>
        <Navbar />
            <LoginForm/>
        <Footer/>
    </>
  )
}

export default Login