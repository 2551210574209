import React from 'react'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import AddForm from '../components/AddForm'

export const Add = () => {
  return (
    <>
        <Navbar />
            <AddForm/>
        <Footer/>
    </>
  )
}

export default Add