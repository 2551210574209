import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import styled from 'styled-components'

import { FWContainer, Information, Warning, Label, Input, SubmitButton, Text } from '../styles/GlobalStyles'

import { BASE_URL } from "../baseurl";

const Linka = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

const EMAIL_REGEX = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

const RegForm = () => {

    const navigate = useNavigate();
    const [validEmail, setValidEmail] = useState(false);
    const [validMatch, setValidMatch] = useState(false);
    const [message, setMessage] = useState('')
    const [user, setUser] = useState({
        email: '',
        passwd: '',
        cpasswd: '',
        firstName: '',
        lastName: ''
    });

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(user.email));
    }, [user.email])

    useEffect(() => {
        setValidMatch(user.passwd === user.cpasswd);
    }, [user.passwd, user.cpasswd])

    const handleChange = (e) => {
        setUser((prev) => ({...prev, [e.target.name]: e.target.value}));
    };

    const handleClick = async (e) => {
        e.preventDefault()

        try {
            await axios.post(`${BASE_URL}/api/v1/register`, user,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(function (response) {
                if (response.status === 201) navigate('/login')
              })
        } catch (err) {
            if (err.response.status === 409) setMessage('Podany adres e-mail jest już zarejestrowany !')
        }
    }

  return (
    <FWContainer>
        {message && <Information>{message}</Information>}
        {(!validEmail && user.email) && <Warning>(Nieprawidłowy adres e-mail.)</Warning>}
        <Label>Adres e-mail:</Label>
        <Input width="30vw" required placeholder='email' onChange={handleChange} name='email'/>
        <Label>Hasło:</Label>
        <Input width="30vw" required placeholder='hasło' onChange={handleChange} name='passwd' type="password"/>
        <Label>Potwierdź hasło:</Label>
        {!validMatch && <Warning>(Hasła muszą być identyczne.)</Warning>}
        <Input width="30vw" required placeholder='powtórz hasło' onChange={handleChange} name='cpasswd' type="password"/>
        <Label>Imię:</Label>
        <Input width="30vw" placeholder='np. Jan' onChange={handleChange} name='firstName' type="text"/>
        <Label>Nazwisko:</Label>
        <Input width="30vw" placeholder='np. Kowalski' onChange={handleChange} name='lastName' type="text"/>
        <Text>Na podany adres email zostanie wysłany link aktywacyjny.</Text>
        <SubmitButton disabled={!(validMatch && user.passwd) ? true : false} onClick={handleClick}>Zarejestruj się</SubmitButton>
        <Text>
            Masz już konto ?{" "}
            <Linka to='/login'>Zaloguj się.</Linka>
        </Text>
    </FWContainer>
  )
}

export default RegForm