import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import axios from 'axios'

import { BasicContainer, Header2, Linka, Table, TH, TD } from '../styles/GlobalStyles'

import Loading from './Loading'

import { BASE_URL } from '../baseurl'

const ArticlesTable = () => {

    const [ articlesList, setArticlesList] = useState([])
    const [notFound, setNotFound] = useState(false)
    const [connErr, setConnErr] = useState(false)

    const profile = JSON.parse(localStorage.getItem('profile'))

    const authAxios = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `Bearer ${profile?.user?.token}`
        }
    })

    useEffect(() =>{
        const fetchAllArticles = async () =>{
            try {
                await authAxios.get(`${BASE_URL}/api/v1/articles`)
                .then(function (response) {
                    setArticlesList(response?.data)
                  })
            } catch (err) {
                err.code === "ERR_NETWORK" && setConnErr(true)
                err.response.status === 404 && setNotFound(true)
            }
        }
        fetchAllArticles()
    }, [authAxios])

  return (
    ( articlesList.length > 0 ) ?
    <BasicContainer>
        <Header2>Tabela artykułów</Header2>
        <Table>
            <thead>
                <tr>
                    <TH>id</TH>
                    <TH>Tytuł</TH>
                    <TH>Autor</TH>
                    <TH>Data publikacji</TH>
                    <TH>Publiczny</TH>
                </tr>
            </thead>
            <tbody>
                {articlesList.map(article=>(
                    <tr key={article.id}>
                        <TD>{article.id}</TD>
                        <TD><Linka to={`/showarticle/${article.id}`}>{article.title}</Linka></TD>
                        <TD>{article.author_name}</TD>
                        <TD><Moment format="DD-MM-YYYY HH:mm:ss">{article.publish_date}</Moment></TD>
                        {article.public ? <TD>Tak</TD> : <TD>Nie</TD>}
                    </tr>
                ))}
            </tbody>
        </Table>
    </BasicContainer>
    : notFound ? <Header2>Nie masz żadnych artykułów !</Header2>
    : connErr ? <Header2>Nie można połączyć się API !</Header2>
    : <Loading/>
  )
}

export default ArticlesTable