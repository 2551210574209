import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { ColumnContainer, Left, Right } from '../styles/GlobalStyles'

import Navbar from '../components/Navbar'
import ArticleShort from '../components/ArticleShort'
import ArticleSearch from '../components/ArticleSearch'
import NewestArticles from '../components/NewestArticles'
import RandomArticles from '../components/RandomArticles'
import Footer from '../components/Footer'
import Loading from '../components/Loading'

import { BASE_URL } from "../baseurl";

export const getRandom = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

export const Home = () => {

    const [articles, setArticles] = useState([])
    const [connErr, setConnErr] = useState(false)
    const [articlesNotFound, setArticlesNotFound] = useState(false)

    useEffect(() =>{
        const fetchAllArticles = async () =>{
            try {
                setConnErr(false)
                const res = await axios.get(`${BASE_URL}/api/v1/articles`)
                setArticles(res.data)
            } catch(err) {
                err.code === "ERR_NETWORK" && setConnErr(true)
                err.code === "ERR_BAD_REQUEST" && setArticlesNotFound(true)
            }
        }
        fetchAllArticles()
    }, [])

    let lastArticles = []
    let randomArticles = []
    if (articles.length > 0) {
        lastArticles = articles.slice(0, 5)
        randomArticles = getRandom(articles, articles.length<5 ? articles.length : 5)
    }

  return (
    <>
        <Navbar />
        <ColumnContainer>
            <Left>
                {articles.length ?
                articles.map(article=>(
                    <ArticleShort key={article.id} article={article} />
                ))
                : articlesNotFound ? <h1>Nie znaleziono artykułów !</h1>
                : connErr ? <h1>Nie można połączyć się API !</h1>
                : <Loading/>}

            </Left>
            <Right>
                <ArticleSearch/>
                <NewestArticles lastArticles={lastArticles}/>
                <RandomArticles randomArticles={randomArticles}/>
            </Right>
        </ColumnContainer>
        <Footer/>
    </>
  )
}

export default Home
