import React from 'react'

import { BasicContainer, Header4, Linka, ArticlesList, ArticleListItem } from '../styles/GlobalStyles'

export const RandomArticles = ({randomArticles}) => {

  return (
    <BasicContainer>
      <Header4>Losowe artykuły</Header4>
      <ArticlesList>
          {randomArticles.map(article=>(
            article.public &&
              <ArticleListItem key={article.id}>
                <Linka to={`/showarticle/${article.id}`}>
                  {article.title}
                </Linka>
              </ArticleListItem>
              ))} 
      </ArticlesList>
    </BasicContainer>
  )
}

export default RandomArticles
