import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import FileBase from 'react-file-base64'
import { BASE_URL } from '../baseurl'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { AuthContext } from '../context/authContext'
import { CustomButton, FWContainer, Header2, Information, Input, Label, SubmitButton, DefaultAvatar,
    ProfileImage } from '../styles/GlobalStyles'

const ProfileContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    border-radius: 5px;
    width: 70vw;
    min-height: 150px;
    overflow: hidden;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    padding-bottom: 20px;

    @media (max-width: 576px) {
        width: 90vw;
    }
`

const ProfileHeader = styled.div`
    position: relative;
    width: 100%;
    height: 150px;
    background: linear-gradient(45deg, #ff0055, #0073df);
`

const ImageBox = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30%;
    width: 150px;
    height: 150px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.4);
    overflow: hidden;
    border: 2px solid white;
    transition: 0.5s;

    &:hover {
        width: 200px;
        height: 200px;
        bottom: -40%;
    };
`

const ProfileBody = styled.div`
    padding-top: 60px;
`

const ProfileEditForm = styled.div`
    position: absolute;
    background: white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    opacity: ${props => props.show ? 1 : 0};
    pointer-events: ${props => props.show ? 'auto' : 'none'};
    transform: ${props => props.show ? 'translateY(-50%) translateX(-50%)' : 'translateY(-70%) translateX(-50%)'};
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;

`

const FileUploader = styled.div`
    width: ${props => props.width};
    min-width: 210px;
    margin: 10px;
    border-radius: 5px;
    border: 0.5px solid #75bafa;
    padding: 7px;
    font-size: 1.1rem;
    transition: all 0.3s ease;

    &:hover {
        background-color: #4ea9ff;
        color: white;
    };
`

const Profile = () => {

    const [message, setMessage] = useState('')

    const {currentUser, login} = useContext(AuthContext)
    const [showEditForm, setShowEditForm] = useState(false);
    const [user, setUser] = useState({
        email: currentUser.user.email,
        firstName: currentUser.user.firstName,
        lastName: currentUser.user.lastName,
        publicId: currentUser.user.publicId,
        img: currentUser.user.img,
        token: currentUser.user.token
    });

    const handleEditChange = (e) => {
        setUser((prev) => ({...prev, [e.target.name]: e.target.value}));
    };

    const handleImgChange = ({base64}) => {
        var stringLength = base64.length - 'data:image/png;base64,'.length;
        var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
        var sizeInKb=sizeInBytes/1000;
        if (sizeInKb > 512) {
            setMessage('Maksymalny rozmiar pliku to 512kb.')
            return
        }

        setUser({ ...user, img: base64});
        setMessage('')
    };

    const handleEditClick = (e) => {
        setShowEditForm(!showEditForm);
    };

    const authAxios = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `Bearer ${currentUser?.user?.token}`
        }
    })

    const handleUpdateClick = async (e) => {
        e.preventDefault()

        try {
            await authAxios.put(`${BASE_URL}/api/v1/register`, user)
            .then(function (response) {
                if (response.data.result === 'success') {
                    login(user);
                    setShowEditForm(false);
                }
              })
        } catch (err) {
            console.log(err)
            if (err.response.status === 401) setMessage('Błąd autoryzacji !')
        }
    }

    function useOutsideClick(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowEditForm(false)
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

  return (
    <>
        <Navbar/>
            <FWContainer>
                <ProfileContainer>
                    <ProfileHeader>
                        <ImageBox>
                            {currentUser.user.img ? <ProfileImage width="100%" height="100%"src={currentUser.user.img} alt="profile"/>
                            : <DefaultAvatar width="100%" height="100%" fz="5em">
                                {currentUser.user.firstName.charAt(0)}{currentUser.user.lastName.charAt(0)}
                            </DefaultAvatar>}
                        </ImageBox>
                    </ProfileHeader>
                    <ProfileBody>
                        <Header2>{currentUser.user.firstName} {currentUser.user.lastName}</Header2>
                        <Header2>{currentUser.user.email}</Header2>
                    </ProfileBody>
                    <CustomButton onClick={handleEditClick}>Edytuj</CustomButton>
                </ProfileContainer>
                <ProfileEditForm ref={wrapperRef} {...(showEditForm && {show:true})}>
                    {message && <Information>{message}</Information>}
                    <Label>Imię:</Label>
                    <Input width='20vw' value={user.firstName} onChange={handleEditChange} name="firstName"></Input>
                    <Label>Nazwisko:</Label>
                    <Input width='20vw' value={user.lastName} onChange={handleEditChange} name="lastName"></Input>
                    <Label>Obraz:</Label>
                    <FileUploader width='20vw'>
                        <FileBase
                            type="file"
                            multiple={false}
                            onDone={handleImgChange}
                        />
                    </FileUploader>
                    <SubmitButton width='20vw' onClick={handleUpdateClick}>Aktualizuj</SubmitButton>
                </ProfileEditForm>
            </FWContainer>
        <Footer/>
    </>
  )
}

export default Profile