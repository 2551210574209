import React from 'react'
import styled from 'styled-components'

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 50vh;
    align-items: center;
`

const Circle = styled.div`
    width: 60px;
    height: 60px;
    border: 10px solid #7dbefa;
    border-color: #7dbefa #c4e2ff #7dbefa #cee7ff;
    border-radius: 50%;
    /* -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite; */
    animation:spin 1s linear infinite;

    /* @-moz-keyframes spin {
        100% { -moz-transform: rotate(360deg); }
    }
    @-webkit-keyframes spin {
        100% { -webkit-transform: rotate(360deg); }
    } */
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform:rotate(360deg);
        }  
    }
`

const Loading = () => {
  return (
    <LoadingWrapper>
        <Circle/>
    </LoadingWrapper>
  )
}

export default Loading