import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Add from './pages/Add';
import Home from './pages/Home';
import ShowArticle from './pages/ShowArticle';
import Register from './pages/Register';
import Login from './pages/Login';
import ArticlesFound from './pages/ArticlesFound';
import Panel from './pages/Panel';
import MyArticles from './pages/MyArticles';
import AccountManager from './pages/AccountManager'
import PasswordReset from './pages/PasswordReset';
import Profile from './pages/Profile';
import { AuthContext } from './context/authContext';

const App = () => {

  const {currentUser} = useContext(AuthContext)

  const ProtectedRoute = ({children}) => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }
    return children;
  };

    return (
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/add' element={<ProtectedRoute><Add/></ProtectedRoute>}/>
            <Route path='/showarticle/:id' element={<ShowArticle/>}/>
            <Route path='/myarticles' element={<ProtectedRoute><MyArticles/></ProtectedRoute>}/>
            <Route path='/found' element={<ArticlesFound/>}/>
            <Route path='/register' element={<Register/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/panel' element={<ProtectedRoute><Panel/></ProtectedRoute>}/>
            <Route path='/manage' element={<ProtectedRoute><AccountManager/></ProtectedRoute>}/>
            <Route path='/reset' element={<PasswordReset/>}/>
            <Route path='/reset/:token' element={<PasswordReset/>}/>
            <Route path='/profile' element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
            
        </Routes>
      </BrowserRouter>
    )
  };
  
  export default App;