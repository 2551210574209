import { Search } from '@mui/icons-material'
import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { BasicContainer, Header4 } from '../styles/GlobalStyles'

import { BASE_URL } from "../baseurl";

const Wrapper = styled.div`
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
`

const SearchInput = styled.input`
    padding: 5px;
    font-size: 1rem;
    width: 100%;
    border: 0.5px solid #4ea9ff;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: all 0.1s ease-out;

    &:focus {
        outline: 2px solid #75bafa;
    }
`

const SearchButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    padding: 5px;
    background-color: #4ea9ff;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: white;
`

export const ArticleSearch = () => {

    const navigate = useNavigate();
    const [search, setSearch] = useState('')
    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    const onFormSubmit = async (e) => {
        e.preventDefault()

        try {
            await axios.get(`${BASE_URL}/api/v1/search/${search}`)
            .then(function (res) {
                navigate('/found', {state: res.data});
              })
        } catch (err) {
            if (err.response.status === 404) navigate('/found');
        }
    }

  return (
    <form onSubmit={onFormSubmit}>
        <BasicContainer>
            <Header4>Wyszukiwarka</Header4>
            <Wrapper>
                <SearchInput onChange={handleChange} placeholder='szukaj' name='searchInput'></SearchInput> 
                <SearchButton type="submit" onSubmit={onFormSubmit}><Search/></SearchButton>
            </Wrapper>
        </BasicContainer>
    </form>
  )
}

export default ArticleSearch