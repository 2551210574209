import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Moment from 'react-moment'

import { FWContainer, Header2, Linka, Table, TH, TD } from '../styles/GlobalStyles'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Loading from '../components/Loading'

import { BASE_URL } from "../baseurl";

const MyArticles = () => {

    var user = JSON.parse(localStorage.getItem('profile'))

    const [articles, setArticles] = useState([])
    const [notFound, setNotFound] = useState(false)
    const [connErr, setConnErr] = useState(false)
    const [unauthorized, setUnauthorized] = useState(false)

    const authAxios = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `Bearer ${user?.user?.token}`
        }
    })

    useEffect(() =>{
        const fetchMyArticles = async () =>{
            try {
                setConnErr(false)
                await authAxios.get(`${BASE_URL}/api/v1/myarticles`)
                .then(function (response) {
                    setArticles(response.data)
                  })
            } catch(err) {
                err.code === "ERR_NETWORK" && setConnErr(true)
                err.response.status === 404 && setNotFound(true)
                err.response.status === 401 && setUnauthorized(true)
            }
        }
        fetchMyArticles()
    }, [authAxios])

  return (
    <>
        <Navbar />
        { ( articles.length > 0 ) ?
        <FWContainer>
            <Header2>Moje artykuły</Header2>
            <Table>
                <thead>
                    <tr>
                        <TH>id</TH>
                        <TH>Tytuł</TH>
                        <TH>Data publikacji</TH>
                        <TH>Publ.</TH>
                    </tr>
                </thead>
                <tbody>
                    {articles.map(article=>(
                        <tr key={article.id}>
                            <TD>{article.id}</TD>
                            <TD><Linka to={`/showarticle/${article.id}`}>{article.title}</Linka></TD>
                            <TD><Moment format="DD-MM-YYYY HH:mm:ss">{article.publish_date}</Moment></TD>
                            {article.public ? <TD>Tak</TD> : <TD>Nie</TD>}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </FWContainer>
        : unauthorized ? <Header2>Brak dostępu !</Header2>
        : notFound ? <Header2>Nie masz żadnych artykułów !</Header2>
        : connErr ? <Header2>Nie można połączyć się API !</Header2>
        : <Loading/>}
        <Footer/>
    </>
  )
}

export default MyArticles