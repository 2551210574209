import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { DefaultAvatar, ProfileImage } from '../styles/GlobalStyles'


import { ManageAccounts, AccountCircle, HelpOutline, Logout } from '@mui/icons-material';
import { AuthContext } from '../context/authContext';

const Dropdown = styled.div`
    position: relative;
`

const DropdownMenu = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 230px;
  top: 42px;
  right: 0;
  color: #000000;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  opacity: ${props => props.show ? 1 : 0};
  pointer-events: ${props => props.show ? 'auto' : 'none'};
  transform: ${props => props.show ? 'translateY(0) translateX(0)' : 'translateY(-20px) translateX(20px)'};
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
`

const UList = styled.ul`
  list-style: none;
  text-align: center;
`

const ListItem = styled.li`
  margin: 18px 10px;
`

const Linka = styled(Link)`
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0 15px;
  cursor: pointer;
  text-decoration: none;
  color: #001369;

  &:hover {
    background-color: #dcdee7;
  }
`

const MenuItemIcon = styled.span`
  margin-right: 10px;
  color: #0066ff;
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

const Avatar = () => {

  const {currentUser, logout} = useContext(AuthContext)
  const [showMenu, setShowMenu] = useState(false);

  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  const handleClick = (e) => {
    setShowMenu(!showMenu);
  }

  const handleLogout = () => {
    logout();
  }

  return (
    <Dropdown ref={wrapperRef}>
      {currentUser.user.img ? <ProfileImage width="35px" height="35px" pointer src={currentUser.user.img} alt="profile" onClick={handleClick}/>
      : <DefaultAvatar width="35px" height="35px" fz="1.1em" pointer onClick={handleClick}>
          {currentUser.user.firstName.charAt(0)}{currentUser.user.lastName.charAt(0)}
      </DefaultAvatar>}

      <DropdownMenu {...(showMenu && {show:true})}>
          <ImageWrapper>
            {currentUser.user.img ? <ProfileImage width="70px" height="70px" src={currentUser.user.img} alt="icon"/>
            : <DefaultAvatar width="70px" height="70px" fz="2.2em">
                {currentUser.user.firstName.charAt(0)}{currentUser.user.lastName.charAt(0)}
            </DefaultAvatar>}
          </ImageWrapper>

          <UList>
            <ListItem>{currentUser.user.firstName} {currentUser.user.lastName}</ListItem>
            <ListItem>{currentUser.user.email}</ListItem>
          </UList>
          <Linka to={'/manage'}><MenuItemIcon><ManageAccounts/></MenuItemIcon>Zarządzaj kontem</Linka>
          <Linka to={'/profile'}><MenuItemIcon><AccountCircle/></MenuItemIcon>Profil</Linka>
          <Linka to={'/'}><MenuItemIcon><HelpOutline/></MenuItemIcon>Pomoc</Linka>
          <Linka onClick={handleLogout}><MenuItemIcon><Logout/></MenuItemIcon>WYLOGUJ</Linka>
      </DropdownMenu>
    </Dropdown>
  )
}

export default Avatar