import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import styled from 'styled-components'

import { FWContainer, Information, Label, Input, SubmitButton, Text } from '../styles/GlobalStyles'

import { BASE_URL } from "../baseurl";
import { AuthContext } from '../context/authContext'

const Linka = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

const LoginForm = () => {

    const navigate = useNavigate();
    const [message, setMessage] = useState('')
    const [user, setUser] = useState({
        email: '',
        passwd: '',
    });
    const {login} = useContext(AuthContext)

    const handleChange = (e) => {
        setUser((prev) => ({...prev, [e.target.name]: e.target.value}));
    };

const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
        await axios.post(`${BASE_URL}/api/v1/login`, user,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })
        .then(function (response) {
            if (response.data.result === 'UNCONFIRMED')
                setMessage('Konto niezweryfikowane !')
            if (response.data.user) {
            //   localStorage.setItem('profile', JSON.stringify({ ...response?.data }))
                login( response?.data.user );
                navigate('/')
            }
          })
    } catch (err) {
        if (err.response.status === 401) setMessage('Nieprawidłowe hasło !')
        else if (err.response.status === 404) setMessage('Nie znaleziono podanego adresu e-mail !')
    }
}

  return (
    <form onSubmit={onFormSubmit}>
        <FWContainer>
            {message && <Information>{message}</Information>}

            <Label>Adres e-mail:</Label>
            <Input width="30vw" required placeholder='email' onChange={handleChange} name='email'/>
            <Label>Hasło:</Label>
            <Input width="30vw" required placeholder='hasło' onChange={handleChange} name='passwd' type="password"/>
            <SubmitButton type="submit" onSubmit={onFormSubmit}>Zaloguj się</SubmitButton>

            <p>
                Nie pamiętasz hasła ?{" "}
                <Linka to='/reset'>Kliknij tutaj.</Linka>
            </p>
            <Text>
                Nie masz konta ?{" "}
                <Linka to='/register'>Kliknij aby utworzyć.</Linka>
            </Text>
        </FWContainer>
    </form>
  )
}

export default LoginForm