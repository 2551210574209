import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ResetForm from '../components/ResetForm'
import axios from 'axios'

import { BASE_URL } from '../baseurl'
import styled from 'styled-components'
import PassForm from '../components/PassForm'

const Information = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 2rem;
    font-weight: 500;
    color: red;
`

const PasswordReset = () => {
    
  const {token} = useParams()
  const [message, setMessage] = useState('')
  const [user, setUser] = useState({})
  const [tokenConfirmed, setTokenConfirmed] = useState(false)
  const [sendRequest, setSendRequest] = useState(token)

  const resetPassword = async () => {

    try {
        await axios.get(`${BASE_URL}/api/v1/reset/${token}`)
        .then(function (response) {
            if (response.data.reset.token === 'confirmed') {
              setTokenConfirmed(true)
              setUser(response.data)
            }
        })
        setSendRequest(false)
    } catch (err) {
        if (err.response.status === 401) setMessage('Nie prawidłowy token resetu !')
    }
  }
  sendRequest && resetPassword();

  return (
    <>
        <Navbar />
            {message && <Information>{message}</Information>}
            {tokenConfirmed ? <PassForm user={user}/>
            : <ResetForm/>}
        <Footer/>
    </>
  )
}

export default PasswordReset
