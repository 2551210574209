import React from 'react'
import styled from 'styled-components'
import notExistImg from '../Images/404.jpg'

const Container = styled.div`
    padding: 10px 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
`

const Title = styled.h2`
    margin-top: 20px;
    font-size: 3rem;
    font-weight: 400;
`

const NotExistImage = styled.img`
  width: 25vw;
  margin-top: 30px;

`

const NotExists = () => {
  return (
    <Container>
      <Title>
        Artykuł nie został odnaleziony.
      </Title>
      <NotExistImage src={notExistImg} alt='Not exists'></NotExistImage>
    </Container>
  )
}

export default NotExists