import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FWContainer, Information, Label, Input, SubmitButton } from '../styles/GlobalStyles'

import { BASE_URL } from '../baseurl'

const ResetForm = () => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('')
    const navigate = useNavigate();

    const handleChange = (e) => {
        setEmail((prev) => ({...prev, [e.target.name]: e.target.value}));
    };

    const handleClick = async (e) => {
        e.preventDefault()

        try {
            await axios.post(`${BASE_URL}/api/v1/reset`, email)
            .then(function (response) {
                if (response.data.result === 'confirmed') navigate('/login')
                else setMessage('Coś poszło nie tak !')
            })
        } catch (err) {
            if (err.response.status === 404) setMessage('Nie znaleziono podanego adresu e-mail !')
        }
    }

  return (
    <FWContainer>
        {message && <Information>{message}</Information>}
        <Label>Wpisz swój adres e-mail:</Label>
        <Input width="30vw" required placeholder='email' onChange={handleChange} name='email'/>
        <SubmitButton onClick={handleClick}>Zresetuj hasła</SubmitButton>
        <p>Na twój adres email zostanie wysłana wiadomość z linkiem do zresetowania hasła.</p>
    </FWContainer>
  )
}

export default ResetForm