import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { BasicContainer, Header2, Table, TH, TD, DefaultAvatar, ProfileImage } from '../styles/GlobalStyles'

import Loading from './Loading'

import { BASE_URL } from '../baseurl'

const UsersTable = () => {

    const [ usersList, setUsersList ] = useState([])
    const [ deleted, setDeleted ] = useState(false)
    const [ notFound, setNotFound ] = useState(false)
    const [ connErr, setConnErr ] = useState(false)

    const profile = JSON.parse(localStorage.getItem('profile'))

    const authAxios = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `Bearer ${profile?.user?.token}`
        }
    })

    useEffect(() =>{
        const fetchUsers = async () =>{
            try {
                await authAxios.get(`${BASE_URL}/api/v1/users`)
                .then(function (response) {
                    setUsersList(response?.data)
                  })
            } catch (err) {
                err.code === "ERR_NETWORK" && setConnErr(true)
                err.response.status === 404 && setNotFound(true)
            }
        }
        fetchUsers()
        setDeleted(false)
    }, [deleted, authAxios])

    const handleDeleteClick = async (id) => {
        try {
            await authAxios.delete(`${BASE_URL}/api/v1/deluser/${id}`)
            .then(function () {
                setDeleted(true)
              })
        } catch(err) {
            err.code === "ERR_NETWORK" && setConnErr(true)
            err.response.status === 404 && setNotFound(true)
        } 
    }

  return (
    ( usersList.length > 0 ) ?
    <BasicContainer>
        <Header2>Tabela użytkowników</Header2>
        <Table>
            <thead>
                <tr>
                    <TH>id</TH>
                    <TH></TH>
                    <TH>e-mail</TH>
                    <TH>Imię</TH>
                    <TH>Nazwisko</TH>
                    <TH>Potw.</TH>
                    <TH></TH>
                </tr>
            </thead>
            <tbody>
                {usersList.map(user=>(
                    <tr key={user.id}>
                        <TD>{user.id}</TD>
                        <TD>
                            {user.profile_image ? <ProfileImage width="30px" height="30px" src={user.profile_image} alt="icon"/>
                            : <DefaultAvatar width="30px" height="30px" fz="0.9em">
                                {user.first_name.charAt(0)}{user.last_name.charAt(0)}
                            </DefaultAvatar>}  
                        </TD>
                        <TD>{user.email}</TD>
                        <TD>{user.first_name}</TD>
                        <TD>{user.last_name}</TD>
                        {user.confirmed ? <TD>Tak</TD> : <TD>Nie</TD>}
                        <TD>
                            <button onClick={()=>handleDeleteClick(user.id)}>Usuń</button>
                        </TD>
                    </tr>
                ))}
            </tbody>
        </Table>
    </BasicContainer>
    : notFound ? <Header2>Nie masz żadnych użytkowników !</Header2>
    : connErr ? <Header2>Nie można połączyć się API !</Header2>
    : <Loading/>
  )
}

export default UsersTable