import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FWContainer, Warning, Label, Input, SubmitButton } from '../styles/GlobalStyles'

import { BASE_URL } from '../baseurl'

const PassForm = props => {

    const navigate = useNavigate();
    const [validMatch, setValidMatch] = useState(false);
    const [newPass, setNewPass] = useState({
        user: props.user.reset.email,
        passwd: '',
        cpasswd: '',
    });

    useEffect(() => {
        setValidMatch(newPass.passwd === newPass.cpasswd);
    }, [newPass.passwd, newPass.cpasswd])

    const handleChange = (e) => {
        setNewPass((prev) => ({...prev, [e.target.name]: e.target.value}));
    };

    const handleClick = async (e) => {
        e.preventDefault()

        try {
            await axios.post(`${BASE_URL}/api/v1/reset`, newPass,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(function (response) {
                if (response.data.result === 'changed') navigate('/login')
              })
        } catch (err) {
            console.log(err)
        }
    }

  return (
    <FWContainer>
        <Label>Podaj nowe hasło:</Label>
        <Input width="30vw" required placeholder='hasło' onChange={handleChange} name='passwd' type="password"/>
        <Label>Potwierdź nowe hasło:</Label>
        {!validMatch && <Warning>(Hasła muszą być identyczne.)</Warning>}
        <Input width="30vw" required placeholder='powtórz hasło' onChange={handleChange} name='cpasswd' type="password"/>
        <SubmitButton disabled={!(validMatch && newPass.passwd) ? true : false} onClick={handleClick}>Ustaw hasło</SubmitButton>
    </FWContainer>
  )
}

export default PassForm