import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import styled from 'styled-components'

import { BASE_URL } from "../baseurl";

import { FWContainer, Information, Header2, Warning, Label, Input, SubmitButton } from '../styles/GlobalStyles'

const Section = styled.div`
  border: 1px solid #0084ff;;
  border-radius: 5px;
  width: 75%;
  padding: 20px;
  margin: 15px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const EMAIL_REGEX = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

const AccountManager = () => {

  const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  const navigate = useNavigate();
  const [message, setMessage] = useState('')
  const [validEmail, setValidEmail] = useState(false);
  const [newEmail, setNewEmail] = useState({
      email: '',
      passwd: ''
  });
  const [validMatch, setValidMatch] = useState(false);
  const [newPasswd, setNewPasswd] = useState({
    oldPasswd: '',
    newPasswd: '',
    confNewPasswd: ''
  });
  const [deleteAcc, setDeleteAcc] = useState({
    passwd: ''
  });

  useEffect(() => {
      setValidEmail(EMAIL_REGEX.test(newEmail.email));
  }, [newEmail.email])

  useEffect(() => {
    setValidMatch(newPasswd.newPasswd === newPasswd.confNewPasswd);
  }, [newPasswd.newPasswd, newPasswd.confNewPasswd])

  const handleNewEmailChange = (e) => {
    setNewEmail((prev) => ({...prev, [e.target.name]: e.target.value}));
  };

  const handleNewPasswdlChange = (e) => {
    setNewPasswd((prev) => ({...prev, [e.target.name]: e.target.value}));
  };

  const handleDeleteChange = (e) => {
    setDeleteAcc((prev) => ({...prev, [e.target.name]: e.target.value}));
  };

  const authAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        Authorization: `Bearer ${user?.user?.token}`
    }
  })

  const handleNewEmailClick = async (e) => {
    e.preventDefault()

    try {
        await authAxios.post(`${BASE_URL}/api/v1/email`, newEmail,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })
        .then(function (response) {
            if (response.data.user) {
                localStorage.setItem('profile', JSON.stringify({ ...response?.data }))
                navigate(0)
            }
          })
    } catch (err) {
        console.log(err)
        if (err.response.status === 401) setMessage('Błąd autoryzacji !')
        if (err.response.status === 409) setMessage('Email zajęty !')
    }
  }

  const handleNewPasswdClick = async (e) => {
    e.preventDefault()

    try {
        await authAxios.post(`${BASE_URL}/api/v1/passwd`, newPasswd,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })
        .then(function (response) {
            if (response.data.result === 'changed') {
                navigate(0)
            }
          })
    } catch (err) {
        if (err.response.status === 401) setMessage('Błąd autoryzacji !')
        if (err.response.status === 404) setMessage('Nie znaleziono !')
    }
  }

  const handleDeleteClick = async (e) => {
    e.preventDefault()

    try {
        await authAxios.post(`${BASE_URL}/api/v1/deluser`, deleteAcc,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })
        .then(function (response) {
            if (response.data.result === 'deleted') {
              localStorage.clear()
              navigate("/");
              navigate(0);
            }
          })
    } catch (err) {
        if (err.response.status === 401) setMessage('Błąd autoryzacji !')
        if (err.response.status === 404) setMessage('Nie znaleziono !')
    }
  }

  return (
    <>
    <Navbar />
    <FWContainer>
      {message && <Information>{message}</Information>}
      <Section>
        <Header2>Zmiana adresu email</Header2>
        {(!validEmail && newEmail.email) && <Warning>(Nieprawidłowy adres e-mail.)</Warning>}
        <Label>Wpisz nowy adres e-mail:</Label>
        <Input width='30vw' required placeholder='email' onChange={handleNewEmailChange} name='email'/>
        <Label>Zatwierdź nowy adres hasłem:</Label>
        <Input width='30vw' required placeholder='hasło' onChange={handleNewEmailChange} name='passwd' type="password"/>
        <SubmitButton onClick={handleNewEmailClick}>Zmień adres email</SubmitButton>
      </Section>
      <Section>
        <Header2>Zmiana hasła</Header2>
        <Label>Aktualne hasło:</Label>
        <Input width='30vw' required placeholder='wpisz aktualne hasło' onChange={handleNewPasswdlChange} name='oldPasswd' type="password"/>
        <Label>Nowe hasło:</Label>
        <Input width='30vw' required placeholder='wpisz nowe hasło' onChange={handleNewPasswdlChange} name='newPasswd' type="password"/>
        <Label>Potwierdź nowe hasło:</Label>
        {!validMatch && <Warning>(Hasła muszą być identyczne.)</Warning>}
        <Input width='30vw' required placeholder='wpisz potwierdź hasło' onChange={handleNewPasswdlChange} name='confNewPasswd' type="password"/>
        <SubmitButton disabled={!(validMatch && newPasswd.newPasswd) ? true : false} onClick={handleNewPasswdClick}>Zmień hasło</SubmitButton>
      </Section>
      <Section>
        <Header2>Usunięcie konta</Header2>
        <Label>Zatwierdź usunięcie konta hasłem:</Label>
        <Input width='30vw' required placeholder='hasło' onChange={handleDeleteChange} name='passwd' type="password"/>
        <SubmitButton onClick={handleDeleteClick}>Usuń konto</SubmitButton>
      </Section>
    </FWContainer>
    <Footer/>
    </>
  )
}

export default AccountManager