import React from 'react'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import RegForm from '../components/RegForm'

export const Register = () => {
  return (
    <>
        <Navbar />
            <RegForm/>
        <Footer/>
    </>
  )
}

export default Register