import styled from "styled-components";
import { Link } from 'react-router-dom'

const ColumnContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    
    @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
    }
`

const BasicContainer = styled.div`
   padding: 10px;
`

const FWContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Header2 = styled.h2`
    text-align: center;
    margin: 5px 0 15px 0;
    font-weight: 400;
`

const Header4 = styled.h4`
    margin-top: 20px;
    font-size: 1.5rem;
    font-weight: 300;
`

const Left = styled.div`
    flex: 3;
`

const Right = styled.div`
    flex: 1;
    border-left: 1px solid grey;
`

const Information = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 2rem;
    font-weight: 500;
    color: red;
`

const Warning = styled.p`
    font-size: 1.0rem;
    font-weight: 500;
    color: red;
`

const Label = styled.h2`
    font-size: 1.1rem;
    font-weight: 400;
`

const Input = styled.input`
    width: ${props => props.width};
    min-width: 200px;
    margin: 10px;
    border-radius: 5px;
    border: 0.5px solid #75bafa;
    padding: 7px;
    font-size: 1.1rem;
    transition: all 0.1s ease-out;

    &:focus {
        outline: 4px solid #75bafa;
    };
`

const SubmitButton = styled.button`
    width: ${props => props.width};
    min-width: 200px;
    padding: 7px;
    border: 1px solid #4ea9ff;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    background-color: #4ea9ff;
    transition: all 0.3s ease;

    &:hover {
        background-color: #2495ff;
        color: white;
        box-shadow: 2px 2px 19px -10px rgba(66, 68, 90, 1);
    };
`

const PublicationDate = styled.p`
    margin-top: 10px;
    font-size: 1.2rem;
`

const Tag = styled.button`
    margin: 2px 5px;
    padding: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #94cbff;
    color: grey;
    &:hover {
        background-color: #7dbefa;
    };
`

const Linka = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    color: #474747;
    &:hover {
        text-decoration: underline;
        color: black;
    }
`

const ArticlesList = styled.ul`
  padding: 5px 30px;
`

const ArticleListItem = styled.li`
  margin-top: 10px;
`

const Table = styled.table`
    width: 100%;
    border: 1px solid;
    border-collapse: collapse;
    font-size: 0.8em;
`

const TH = styled.td`
    border: 1px solid;
    padding: 10px;
    text-align: center;
`

const TD = styled.td`
    border: 1px solid;
    padding: 5px;
`

const CustomButton = styled.button`
    margin: 0 30px;
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #4ea9ff;
    border-radius: 5px;
    color: #4ea9ff;
    font-size: 1rem;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.3s ease;

    &:hover {
        background-color: #4ea9ff;
        color: white;
    };
`

const DefaultAvatar = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    background-color: #8c00ff;
    color: white;
    font-size: ${props => props.fz};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${props => props.pointer ? 'pointer' : 'default'};
`

const ProfileImage = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
    object-fit: cover;
    border-radius: 50%;
    cursor: ${props => props.pointer ? 'pointer' : 'default'};
`

const Text = styled.p`
    margin: 20px;
`

export { ColumnContainer, BasicContainer, FWContainer, Header2, Header4, Left, Right, Information, Warning,
    Label, Input, SubmitButton, PublicationDate, Tag, Linka, ArticlesList, ArticleListItem, Table, TH, TD,
    CustomButton, DefaultAvatar, ProfileImage, Text}