import React, { useContext, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { AuthContext } from '../context/authContext'

import axios from 'axios'
import styled from 'styled-components'

import { FWContainer, Information, Input, Label, SubmitButton } from '../styles/GlobalStyles'

import QuillEditor from './Editor/QuillEditor'

import { BASE_URL } from "../baseurl";

const CheckBox = styled.div`
    margin: 20px 0;
    font-size: 1.4rem;
    font-weight: 300;   
`

const AddForm = () => {

    const location = useLocation()

    const {currentUser} = useContext(AuthContext)
    const navigate = useNavigate();
    const [message, setMessage] = useState('')
    const [user] = useState(JSON.parse(localStorage.getItem("profile")));
    const [tagsString] = useState(location.state.tags ? location.state.tags.join(" ") : "");

    const authAxios = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `Bearer ${user?.user?.token}`
        }
    })

    const [article, setArticle] = useState({
        title: location.state.title,
        body: location.state.body,
        tags: tagsString,
        author_id: "",
        public: location.state.public
    });

    const [content, setContent] = useState(location.state.edit ? location.state.body : "");
    // const [FileSystem, setFiles] = useState([]);

    const onEditorChange = (value) => {
        setContent(value)
        setArticle((prev) => ({...prev, body: value}));
    }

    const onFilesChange = (files) => {
        // setFiles(files)
    }

    const handleChange = (e) => {
        setArticle((prev) => ({...prev, [e.target.name]: e.target.value, 'author_id': currentUser.user.publicId, 'public': document.getElementById('public').checked}));
    };

    const handleClick = async (e) => {
        e.preventDefault()

        if (article.title === '') {
            setMessage('Pole "Tytuł" jest wymagane !')
            return
        }

        try {
            location.state.edit ?
            await authAxios.put(`${BASE_URL}/api/v1/articles/${location.state.id}`, article) :
            await authAxios.post(`${BASE_URL}/api/v1/articles`, article)
            .then(function (response) {
                // console.log(response);
              })
            setContent('')
            navigate('/')
        } catch (err) {
            // console.log(err.response.status)
            // err.response.status == 401 && setUnauthorized(true)
        }
    }

  return (
    <FWContainer>
        {message && <Information>{message}</Information>}
        <Label>Tytuł</Label>
        <Input width='50vw' value={article.title} placeholder='tytuł artykułu' onChange={handleChange} name='title'/>
        <Label>Treść</Label>
        <QuillEditor
                value={content}
                onEditorChange={onEditorChange}
                onFilesChange={onFilesChange}
            />

        <Label>Tagi</Label>
        <Input width='50vw' value={article.tags} onChange={handleChange} name='tags' placeholder='np. #react #js'/>
        <CheckBox>
            <input onChange={handleChange} type="checkbox" id="public" name="public" value="true" defaultChecked={article.public}/>
            <label htmlFor="public"> Artykuł publiczny</label>
        </CheckBox>
        <SubmitButton onClick={handleClick}>{location.state.edit ? 'Zapisz' : 'Dodaj artykuł'}</SubmitButton>
    </FWContainer>
  )
};

export default AddForm;