import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    background-color: #2997ff;
    color: white;
    width: 100hw;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Footer = () => {
  return (
    <Container>
        <div>Ryszard Baranowski 2022</div>
    </Container>
  )
}

export default Footer
