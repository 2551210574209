import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import Moment from 'react-moment'
import 'moment/locale/pl';
import styled from 'styled-components'

import { BasicContainer, PublicationDate, Tag, CustomButton } from '../styles/GlobalStyles'

import { BASE_URL } from "../baseurl";

const TitleLink = styled(Link)`
    margin-top: 5px;
    font-size: 3rem;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    color: black;
    &:hover {
        text-decoration: underline;
    }
    @media (max-width: 768px) {
        font-size: 2rem;
    }
`

const Tags = styled.div`
    margin-top: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

const Article = styled.p`
    margin-top: 10px;
    font-size: 1.2rem;
    line-height: 1.6;
    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`
const ReadMoreContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`

export const Articleshort = ( { article } ) => {

    const navigate = useNavigate();
    const [isPublic, setIsPublic] = useState(false)

    const handleTagClick = async (tag) => {
        var tagWHash = tag.substring(1);
        
        try {
            await axios.get(`${BASE_URL}/api/v1/search/${tagWHash}`)
            .then(function (res) {
                navigate('/found', {state: res.data});
              })
        } catch (err) {
            if (err.response.status === '404') navigate('/found');
        }
    }

    useEffect(() => {
        setIsPublic(false)
        article.public && setIsPublic(true)
    }, [article.public])

  return (
    isPublic &&
    <BasicContainer>
        <TitleLink to={'/showarticle/' +  article.id}>{article.title}</TitleLink>
        <PublicationDate><Moment format="DD MMMM YYYY HH:mm:ss">{article.publish_date}</Moment></PublicationDate>
        <Tags>
            <h4>Tagi:</h4>
                {article.tags.map(tag=>(
                    <Tag key={tag} onClick={() => handleTagClick(tag)}>{tag}</Tag>
                ))}
        </Tags>
        <Article>{article.body.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/gi," ").substring(0,400)} [...]</Article>
        <ReadMoreContainer>
            <Link to={'/showarticle/' +  article.id}>
                <CustomButton>Czytaj więcej...</CustomButton>
            </Link>
        </ReadMoreContainer>
    </BasicContainer>
  )
}

export default Articleshort