import jwtDecode from 'jwt-decode'
import { useContext } from 'react'
import { AuthContext } from '../context/authContext'

const useAuth = () => {

    const {currentUser} = useContext(AuthContext)
    const token = currentUser?.user?.token
    let isAdmin = false

    if (token) {
        const decoded = jwtDecode(token)
        const { isAdmin } = decoded
        return { isAdmin }
    }
  return { isAdmin }
}

export default useAuth